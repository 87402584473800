// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-all-html-js": () => import("./../../../src/pages/blog/all.html.js" /* webpackChunkName: "component---src-pages-blog-all-html-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jsx-compiler-html-js": () => import("./../../../src/pages/jsx-compiler.html.js" /* webpackChunkName: "component---src-pages-jsx-compiler-html-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-community-js": () => import("./../../../src/templates/community.js" /* webpackChunkName: "component---src-templates-community-js" */),
  "component---src-templates-tutorial-js": () => import("./../../../src/templates/tutorial.js" /* webpackChunkName: "component---src-templates-tutorial-js" */)
}

